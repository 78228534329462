import { normalizeParams } from '#automation/utils'
import type { AutomationRule, AutomationOption, AutomationError, AutomationRuleRaw, AutomationTargetData } from '#automation/types'
import { ActionType, AutomationOperator, AutomationOptionType, AutomationTriggerType, ConditionType, TriggerType } from '#automation/constants'
import type { FieldOption } from '#field/types'
import { FieldType } from '#field/constant'
import { RoleLevel } from '#auth/constant'
import { ConnectionProvider } from '#app-integration/constant'

const $useAutomationSharedState = () => {
  const { setSettingTab } = useBoardSettingsNavigator()
  const { boardData } = useBoardSharedState()
  const { sections } = useBoardSectionsLoader()
  const { currentSettingsPack } = useWorkspaceSharedState()
  const { can } = useBoardAbility()
  const { currentWorkspace, currentBoard } = useWorkspaceSharedState()

  const toast = useToast()

  const currentRule = useState<AutomationRule>(
    'currentRule',
    () => ({} as AutomationRule)
  )
  const currentRuleId = useState<string>('currentRuleId', () => '')
  const sideBarMode = useState<string>('sideBarMode', () => 'rules')
  const allRules = useState<AutomationRule[]>(
    'allRules',
    () => []
  )

  const currentSelectOption = ref<AutomationOption>({} as AutomationOption)
  const currentActionIndex = ref(0)

  const automationError = ref<AutomationError>({
    trigger: '',
    actions: {},
    condition: '',
    ruleName: '',
    taskType: '',
  })

  const dataError = ref<AutomationError>({
    trigger: '',
    actions: {},
    condition: '',
    ruleName: '',
    taskType: '',
  })

  const hasPermission = computed(() => can('dashboard.settings_pack.manage_automations'))

  const styleOnFocus = 'hover:bg-primary-100 bg-primary-50 text-primary-800 border-primary automation automation--drop-shadow'

  const setAllRules = (rules: AutomationRule[]) => {
    allRules.value = rules
  }

  const removeCondition = () => {
    currentRule.value.condition = null
    if (currentSelectOption.value.optionType === AutomationOptionType.CONDITION) {
      currentSelectOption.value = currentRule.value.trigger as AutomationOption
    }
  }

  const removeAction = (index?: number, all?: boolean) => {
    if (all) {
      currentRule.value.actions = [
        {} as AutomationOption,
      ]
      currentActionIndex.value = 0
      currentSelectOption.value = { optionType: AutomationOptionType.ACTION } as AutomationOption
      return
    }

    if (index !== undefined) {
      currentRule.value.actions.splice(index, 1)
    }
  }

  const createNewRule = (source: string, parentId: string, isButton = false) => {
    currentRule.value = {
      name: '',
      source,
      parentId,
      trigger: { optionType: AutomationOptionType.TRIGGER } as AutomationOption,
      actions: [
      { optionType: AutomationOptionType.ACTION } as AutomationOption,
      ] as AutomationOption[],
    } as AutomationRule

    if (isButton) {
      currentRule.value.trigger = {
        optionType: AutomationOptionType.TRIGGER,
        triggerType: AutomationTriggerType.BUTTON,
        type: 'on_click',
        label: 'User manually click the button',
        icon: 'heroicons:cursor-arrow-rays-solid',
      } as AutomationOption
      currentRule.value.type = AutomationTriggerType.BUTTON
    } else {
      currentRule.value.trigger = {
        optionType: AutomationOptionType.TRIGGER,
      } as AutomationOption
    }
  }

  const updateCreateRule = async () => {
    const errorMessage = validateBeforeUpdateCreate()
    if (errorMessage) {
      const tille = currentRuleId.value ? 'Failed to update automation rule' : 'Failed to create automation rule'
      toast.add({
        title: tille,
        description: errorMessage,
      })
      return
    }

    const params = normalizeParams(currentRule.value)
    const { mutate } = useUpdateCreateAutomationRuleMutation(params)
    try {
      await mutate()
    } catch (error) {
      let errorMessage = ''
      if ((error as Error).message === 'NAME_ALREADY_EXISTS') {
        if (currentRule.value.type === AutomationTriggerType.BUTTON) {
          errorMessage = 'Button label already exists.'
          automationError.value.ruleName = errorMessage
          currentSelectOption.value = currentRule.value.trigger
        } else {
          errorMessage = 'Rule name already exists.'
          automationError.value.ruleName = errorMessage
        }
      }

      toast.add({
        icon: 'i-heroicons-x-circle-20-solid',
        title: 'Error',
        description: errorMessage ? errorMessage : (currentRule.value.id ? 'Failed to update automation rule.' : 'Failed to create automation rule.') + ' Please try again.',
      })
      return
    }

    toast.add({
      icon: 'i-heroicons-check-circle',
      title: 'Success',
      description: currentRule.value.id ? 'Automation rule updated successfully' : 'Automation rule created successfully',
    })

    setSettingTab('automations')
  }

  const validateBeforeUpdateCreate = () => {
    // Check if button trigger not have rule name
    if (!currentRule.value.name && currentRule.value.type === AutomationTriggerType.BUTTON) {
      automationError.value.ruleName = 'Button name is required'
      currentSelectOption.value = currentRule.value.trigger

      return automationError.value.ruleName
    }

    // Validate trigger button
    if (currentRule.value.trigger.triggerType === AutomationTriggerType.BUTTON) {
      if (!currentRule.value.taskTypes || currentRule.value.taskTypes.length === 0) {
        automationError.value.taskType = 'Task types is required'
        currentSelectOption.value = currentRule.value.trigger
        return automationError.value.taskType
      }
    }

    // Validate trigger
    const trigger = currentRule.value.trigger
    if (!trigger.label) {
      automationError.value.trigger = 'Trigger is required'
      currentSelectOption.value = trigger
      return automationError.value.trigger
    }

    let isTriggerValid = true
    switch (trigger.type) {
      case TriggerType.TASK_MOVED_TO_SECTION:
      case TriggerType.DUE_DATE_CHANGED:
      case TriggerType.ASSIGNEE_ADDED:
      case TriggerType.STATUS_CHANGED:
        if (
          !trigger.operator ||
          (!trigger.targetValue &&
          [
            AutomationOperator.BEFORE,
            AutomationOperator.AFTER,
            AutomationOperator.IS,
            AutomationOperator.NOT,
            AutomationOperator.IN
          ].includes(trigger.operator as AutomationOperator))) {
          isTriggerValid = false
          break
        }

        if (trigger.operator === AutomationOperator.BETWEEN) {
          const actionTarget = trigger.targetValue as { start: string, end: string }
          if (!actionTarget || !actionTarget.start || !actionTarget.end) {
            isTriggerValid = false
            break
          }
        }

        break

      case TriggerType.CUSTOM_FIELD_CHANGED: {
        if (
          !trigger.operator ||
          !trigger.customField ||
          ([AutomationOperator.IS].includes(trigger.operator as AutomationOperator) && !trigger.targetValue)
        ) {
          isTriggerValid = false
        }

        break
      }

      default:
        break
    }

    if (!isTriggerValid) {
      automationError.value.trigger = 'Finish trigger settings'
      currentSelectOption.value = trigger
      return automationError.value.trigger
    }

    // Validate condition
    const condition = currentRule.value.condition
    if (condition) {
      let isConditionValid = true

      if (!condition.label) {
        isConditionValid = false
      }

      switch (condition.type) {
        case ConditionType.TASK_IN_SECTION:
        case ConditionType.TASK_CREATED_BY:
          if (!condition.targetValue) {
            isConditionValid = false
          }

          break

        case ConditionType.DUE_DATE_IS:
        case ConditionType.ASSIGNEE_IS:
        case ConditionType.TASKTYPE_IS:
        case ConditionType.LABEL_IS:
          if (
            !condition.operator ||
            (!condition.targetValue &&
            [
              AutomationOperator.BEFORE,
              AutomationOperator.AFTER,
              AutomationOperator.IS,
              AutomationOperator.NOT,
              AutomationOperator.IN
            ].includes(condition.operator as AutomationOperator))) {
            isConditionValid = false
            break
          }

          if (condition.operator === AutomationOperator.BETWEEN) {
            const actionTarget = condition.targetValue as { start: string, end: string }
            if (!actionTarget || !actionTarget.start || !actionTarget.end) {
              isConditionValid = false
              break
            }
          }

          break

        case ConditionType.CUSTOM_FIELD_IS: {
          if (
            !condition.operator ||
            !condition.customField ||
            ([AutomationOperator.IS].includes(condition.operator as AutomationOperator) && !condition.targetValue)
          ) {
            isConditionValid = false
          }

          break
        }

        default:
          break
      }

      if (!isConditionValid) {
        automationError.value.condition = 'Finish condition settings or remove it'
        currentSelectOption.value = condition
        return automationError.value.condition
      }
    }

    // Validate actions
    for (let index = 0; index < currentRule.value.actions.length; index++) {
      const action = currentRule.value.actions[index]
      if (!action.label) {
        if (index === 0) {
          automationError.value.actions[index] = 'Action is required.'
        } else {
          automationError.value.actions[index] = 'Finish action or remove it.'
        }

        currentActionIndex.value = index
        currentSelectOption.value = action
        return automationError.value.actions[index]
      }

      let isActionValid = true

      switch (action.type) {
        case ActionType.MOVE_TO_BOARD: {
          if (
            !action.operator
          ) {
            isActionValid = false
            break
          }

          const targetValue = action.targetValue as { board: string, section: string }
          if (!targetValue || !targetValue.board || !targetValue.section) {
            isActionValid = false
          }

          break
        }

        case ActionType.CHANGE_ASSIGNEE:
          if (
            !action.operator ||
            ([AutomationOperator.IS, AutomationOperator.NOT, AutomationOperator.IN, AutomationOperator.ADD,
              AutomationOperator.MOVE, AutomationOperator.SET].includes(action.operator as AutomationOperator) && !action.targetValue)
          ) {
            isActionValid = false
            break
          }

          break
        case ActionType.MOVE_TO_SECTION:
        case ActionType.CHANGE_STATUS:
          if (!action.targetValue) {
            isActionValid = false
          }

          break
        case ActionType.CREATE_SUBTASK:
          if (!action.targetValue || (action.targetValue as string[]).length === 0) {
            isActionValid = false
            break
          }

          (action.targetValue as string[]).forEach((subtask) => {
            if (!subtask || subtask.trim() === '') {
              isActionValid = false
            }
          })

          break

        case ActionType.CHANGE_DUE_DATE: {
          if (!action.operator) {
            isActionValid = false
            break
          }

          const actionTarget = action.targetValue as { start: string, due: string }
          if (action.operator === AutomationOperator.SET) {
            if (!actionTarget || (!actionTarget.start && !actionTarget.due)) {
              isActionValid = false
            }
          }

          break
        }

        case ActionType.SET_CUSTOM_FIELD: {
          if (
            !action.operator ||
            !action.customField ||
            ([AutomationOperator.IS].includes(action.operator as AutomationOperator) && !action.targetValue)
          ) {
            isActionValid = false
          }

          break
        }

        case ActionType.SLACK_SEND_MESSAGE: {
          if (!action.targetValue || !action.connectionId) {
            isActionValid = false
          }

          const targetValue = action.targetValue as {
            type: string
            ids: string[]
            mentions: string[]
            message: string
          }

          if (!targetValue || !targetValue.type || !targetValue.message || targetValue.ids.length === 0) {
            isActionValid = false
          }

          break
        }

        default:
          break
      }

      if (!isActionValid) {
        automationError.value.actions[index] = 'Finish action settings'
        currentActionIndex.value = index
        currentSelectOption.value = action
        return automationError.value.actions[index]
      }
    }

    return ''
  }

  const validateInitialData = async () => {
    dataError.value = {
      trigger: '',
      actions: {},
      condition: '',
      ruleName: '',
      taskType: '',
    }
    const options = [
      {
        optionType: AutomationOptionType.TRIGGER,
        data: currentRule.value.trigger,
      },
      ...currentRule.value.actions.map((action, index) => ({
        optionType: AutomationOptionType.ACTION,
        data: action,
        index,
      }))
    ]
    if (currentRule.value.condition) {
      options.push({
        optionType: AutomationOptionType.CONDITION,
        data: currentRule.value.condition,
      })
    }

    options.forEach(async (option) => await validateOption(option))
  }

  const validateOption = async (option: { optionType: AutomationOptionType, data: AutomationOption, index?: number }) => {
    const isFromSettingPack = currentRule.value.source === 'settings_pack'
    const optionData = option.data

    switch (optionData.type) {
      case ActionType.MOVE_TO_SECTION:
      case ConditionType.TASK_IN_SECTION:
      case TriggerType.TASK_MOVED_TO_SECTION: {
        if (isFromSettingPack) {
          break
        }

        const sectionId = optionData.targetValue as string | string[]
        if (!sectionId || typeof sectionId !== 'string') {
          break
        }

        if (!validateSections(sectionId)) {
          const error = 'This section has been removed from this board'
          setDataErrorMessage(option.optionType, error, option.index || 0)
        }

        break
      }

      case ActionType.CHANGE_ASSIGNEE:
      case ConditionType.ASSIGNEE_IS:
      case TriggerType.ASSIGNEE_ADDED: {
        if (isFromSettingPack) {
          break
        }

        const assigneeId = optionData.targetValue as string | string[]
        if (!assigneeId || typeof assigneeId !== 'string') {
          break
        }

        if (!validateMember(assigneeId)) {
          const error = 'This user has been removed from this board'
          setDataErrorMessage(option.optionType, error, option.index || 0)
        }

        break
      }

      case ActionType.CHANGE_STATUS:
      case TriggerType.STATUS_CHANGED: {
        const statusId = optionData.targetValue as string
        if (!validateStatus(statusId, isFromSettingPack)) {
          const error = isFromSettingPack ? 'This status has been removed from this setting pack' : 'This status has been removed from this board'
          setDataErrorMessage(option.optionType, error, option.index || 0)
        }

        break
      }

      case ActionType.SET_CUSTOM_FIELD:
      case ConditionType.CUSTOM_FIELD_IS:
      case TriggerType.CUSTOM_FIELD_CHANGED: {
        const fieldId = optionData.customField
        const value = optionData.targetValue as string
        const validateMessage = await validateCustomField(fieldId, value, isFromSettingPack)
        setDataErrorMessage(option.optionType, validateMessage, option.index || 0)
        break
      }

      case ConditionType.TASKTYPE_IS: {
        const taskType = optionData.targetValue as string | string[]
        if (!taskType || typeof taskType !== 'string') {
          break
        }

        if (!validateTaskType(taskType, isFromSettingPack)) {
          const error = isFromSettingPack ? 'This task type has been removed from this setting pack' : 'This task type has been removed from this board'
          setDataErrorMessage(option.optionType, error, option.index || 0)
        }

        break
      }

      case ConditionType.LABEL_IS: {
        const labelId = optionData.targetValue as string | string[]
        if (!labelId || typeof labelId !== 'string') {
          break
        }

        if (!validateLabel(labelId, isFromSettingPack)) {
          const error = isFromSettingPack ? 'This label has been removed from this setting pack' : 'This label has been removed from this board'
          setDataErrorMessage(option.optionType, error, option.index || 0)
        }

        break
      }

      case ActionType.MOVE_TO_BOARD: {
        const { board: boardId, section: sectionId } = optionData.targetValue as { board: string, section: string }
        if (boardId) {
          const accessAllBoard = computed(() => can('dashboard.boards.manage_boards'))
          const { result: workspaceBoardResult, load: loadWorkspaceBoard, stop: stopLoadWorkspaceBoard } = useListWorkspaceBoardsLazyQuery({
            workspaceId: currentWorkspace.value.id,
            exceptBaseLevels: accessAllBoard.value ? [] : [RoleLevel.GUEST],
          })
          await loadWorkspaceBoard()
          stopLoadWorkspaceBoard()

          const activeBoards = workspaceBoardResult.value?.boards.filter(board => !board.closed) ?? [currentBoard.value]

          if (!activeBoards.find(board => board.id === boardId)) {
            const error = 'This board has been removed from workspace'
            setDataErrorMessage(option.optionType, error, option.index || 0)
          } else if (sectionId) {
            const { result, load, stop } = useSectionsLazyQuery()
            await load(null, {
              boardId,
            })
            stop()
            if (!result.value?.sections?.find(section => section.id === sectionId)) {
              const error = 'This section has been removed from this board'
              setDataErrorMessage(option.optionType, error, option.index || 0)
            }
          }
        }

        break
      }

      case ActionType.SLACK_SEND_MESSAGE: {
        const connection = optionData.connection
        if (!connection) {
          break
        }

        const connectionId = connection.id
        const slackWorkspaceId = JSON.parse(connection.account)?.workspace?.id

        if (!slackWorkspaceId || !connectionId) {
          break
        }

        const { result, load, stop } = useConnectionsLazyQuery()
        await load(null, {
          provider: ConnectionProvider.SLACK,
          workspaceId: currentWorkspace.value.id,
        })
        stop()

        const validConnection = result.value?.connections.find(cnt => {
          if (cnt.id === connection.id) {
            return true
          }

          const slackConnection = JSON.parse(cnt.account)
          return slackConnection.workspace.id === slackWorkspaceId
        })

        if (!validConnection) {
          const error = 'This connection has been removed from this board.'
          setDataErrorMessage(option.optionType, error, option.index || 0)
          break
        }

        const targetData = optionData.targetValue as {
          type: string
          ids: string[]
          mentions: string[]
          message: string
        }

        if (targetData.type === 'Channel' && targetData.ids.length) {
          const { result: slackChannelsResult, load: loadSlackChannels, stop: stopSlackChannels } = useLazySlackChannelsQuery()
          await loadSlackChannels(null, {
            connectionId: validConnection.id,
          })
          stopSlackChannels()

          if (!slackChannelsResult.value?.slackChannels.find(channel => targetData.ids.includes(channel.id))) {
            const error = 'Channel(s) has been removed from Slack'
            setDataErrorMessage(option.optionType, error, option.index || 0)
            break
          }
        }

        if (targetData.type === 'User' && targetData.ids.length) {
          const { result: slackUsersResult, load: loadSlackUsers, stop: stopSlackUsers } = useLazySlackUsersQuery()
          await loadSlackUsers(null, {
            connectionId
          })
          stopSlackUsers()

          if (!slackUsersResult.value?.slackUsers.find(user => targetData.ids.includes(user.id))) {
            const error = 'User(s) has been removed from Slack'
            setDataErrorMessage(option.optionType, error, option.index || 0)
            break
          }
        }

        if (targetData.mentions.length) {
          const { result: slackUsersResult, load: loadSlackUsers, stop: stopSlackUsers } = useLazySlackUsersQuery()
          await loadSlackUsers(null, {
            connectionId
          })
          stopSlackUsers()

          if (!slackUsersResult.value?.slackUsers.find(user => targetData.mentions.includes(user.id))) {
            const error = 'This user has been removed from Slack'
            setDataErrorMessage(option.optionType, error, option.index || 0)
            break
          }
        }

        break
      }
    }
  }

  const setDataErrorMessage = (optionType: AutomationOptionType, message: string, index: number) => {
    switch (optionType) {
      case AutomationOptionType.TRIGGER:
        dataError.value.trigger = message
        break

      case AutomationOptionType.ACTION:
        dataError.value.actions[index] = message
        break

      case AutomationOptionType.CONDITION:
        dataError.value.condition = message
        break
    }
  }

  const validateSections = (sectionId: string) => {
    const validSectionIds = sections.value.map(section => section.id)
    return validSectionIds.includes(sectionId)
  }

  const validateMember = (memberId: string) => {
    const validAssigneeIds = boardData.value.users.map(user => user.id)
    return validAssigneeIds.includes(memberId)
  }

  const validateStatus = (statusId: string, fromSettingPack: boolean) => {
    if (!statusId) {
      return true
    }

    const validStatusIds = fromSettingPack ? currentSettingsPack.value.statuses.map(status => status.id) : boardData.value.statuses.map(status => status.id)
    return validStatusIds.includes(statusId)
  }

  const validateTaskType = (taskType: string, fromSettingPack: boolean) => {
    const validTaskTypes = fromSettingPack ? currentSettingsPack.value.taskTypes.map(taskType => taskType.id) : boardData.value.taskTypes.map(taskType => taskType.id)
    return validTaskTypes.includes(taskType)
  }

  const validateLabel = (labelId: string, fromSettingPack: boolean) => {
    const validLabels = fromSettingPack ? currentSettingsPack.value.labels.map(label => label.id) : boardData.value.labels.map(label => label.id)
    return validLabels.includes(labelId)
  }

  const validateCustomField = async (fieldId: string, value: string, fromSettingPack: boolean) => {
    let validFields = []
    if (fromSettingPack) {
      const { load, result, stop } = useLazySettingPackCustomFieldsQuery(currentSettingsPack.value.id)
      await load()
      validFields = result.value?.settingsPackFields.map((field) => ({
        id: field.id,
        type: field.type,
        options: (typeof field.options === 'string' ? JSON.parse(field.options) : []) as FieldOption[]
      })) || []
      stop()
    } else {
      validFields = boardData.value.fields.map((field) => ({
        id: field.id,
        type: field.type,
        options: (typeof field.options === 'string' ? JSON.parse(field.options) : []) as FieldOption[]
      }))
    }

    const validField = validFields.find(field => field.id === fieldId)

    if (!validField) {
      return fromSettingPack ? 'This custom field has been removed from this setting pack' : 'This custom field has been removed from this board'
    }

    if (validField.type === FieldType.PEOPLE) {
      validField.options = boardData.value.users.map(user => ({ label: user.fullName, value: user.id }))
    }

    if (value && validField.options.length > 0 && !validField.options.map(option => option.value).includes(value)) {
      return fromSettingPack ? 'This value has been removed from this setting pack' : 'This value has been removed from this board'
    }

    return ''
  }

  const convertRawData = (rawRule: AutomationRuleRaw) => {
    return {
      ...rawRule,
      trigger: {
        ...rawRule.trigger,
        triggerType: rawRule.type,
        optionType: AutomationOptionType.TRIGGER,
        targetData: (rawRule.trigger.targetData ? JSON.parse(rawRule.trigger.targetData as string) : undefined),
        targetValue: (rawRule.trigger.targetValue ? JSON.parse(rawRule.trigger.targetValue as string) : undefined) as AutomationTargetData,
      },
      actions: rawRule.actions.map((action) => ({
        ...action,
        optionType: AutomationOptionType.ACTION,
        targetData: (action.targetData ? JSON.parse(action.targetData as string) : undefined) as AutomationTargetData,
        targetValue: (action.targetValue ? JSON.parse(action.targetValue as string) : undefined) as AutomationTargetData,
      })),
      condition: rawRule.condition
        ? {
          ...rawRule.condition,
          optionType: AutomationOptionType.CONDITION,
          targetData: (rawRule.condition.targetData ? JSON.parse(rawRule.condition.targetData as string) : undefined) as AutomationTargetData,
          targetValue: (rawRule.condition.targetValue ? JSON.parse(rawRule.condition.targetValue as string) : undefined) as AutomationTargetData,
        }
        : null,
      isActive: rawRule.isActive || false,
      modified: rawRule.modified || '',
    } as AutomationRule
  }

  watch(
    () => currentSelectOption.value,
    () => {
      const updatedRule = { ...currentRule.value }

      if (currentSelectOption.value.optionType === AutomationOptionType.TRIGGER) {
        updatedRule.trigger = currentSelectOption.value
        updatedRule.type = currentSelectOption.value.triggerType || ''
      }

      if (currentSelectOption.value.optionType === AutomationOptionType.CONDITION) {
        updatedRule.condition = currentSelectOption.value
      }

      if (currentSelectOption.value.optionType === AutomationOptionType.ACTION) {
        updatedRule.actions[currentActionIndex.value] = currentSelectOption.value
      }

      currentRule.value = updatedRule
    },
    {
      deep: true,
    })

  watch(
    () => currentRule.value.id,
    async () => {
      if (!currentRule.value.id) {
        return
      }

      await validateInitialData()
    })

  return {
    allRules,
    sideBarMode,
    currentRuleId,
    automationError,
    currentSelectOption,
    hasPermission,
    currentRule,
    styleOnFocus,
    currentActionIndex,
    dataError,
    removeCondition,
    removeAction,
    createNewRule,
    updateCreateRule,
    setAllRules,
    validateInitialData,
    convertRawData,
  }
}

export const useAutomationSharedState = createSharedComposable(
  $useAutomationSharedState
)
